/* eslint-disable no-undef */
/* eslint-disable no-bitwise */
import { toast } from 'hooks/use-toast';
import { socialImages } from 'assets/social-images';
import { get, map, set } from 'lodash';
import moment from 'moment';
import React from 'react';
import axios from 'axios';
import {
  ALLOWED_IMAGE_SIZE,
  REGEX,
  SOCIAL_LINKS,
  defaultDateFormat,
} from './constants';
import client from '../apollo';
import { GET_SIGNATURE_ICON_SIGNED_URL } from '../modules/Signature/graphql/Queries';

// Portal related methods
export const injectUsingPortal = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isPortalIdExists = (portalId) => !!injectUsingPortal(portalId);

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isDocumentIdExist = (portalId) =>
  !!getElementFromDocumentId(portalId);
// Check for document Id's exists end

export const formatDate = (
  dateTime,
  format = `${defaultDateFormat} hh:mm A`,
) => {
  if (dateTime && moment && format) {
    return moment(dateTime)?.format(format);
  }

  return dateTime;
};

export const getLiveUrl = (key) =>
  `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${key}?timestamp=${new Date().getTime()}`;

export const getLiveUrlWithoutTimeStamp = (key) =>
  `${process.env.REACT_APP_SOCIAL_ICONS_URL}/asset/${key}`;

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Invalid email address');
      }
      return Promise?.resolve();
    },
  }),
  name: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid name');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    },
  }),
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes(),
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formattedPrice = price || 0;

  return Number(formattedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

export async function fileUpload(signedUrl, image) {
  const formData = new FormData();
  formData.append('file', image);
  const response = await axios.put(signedUrl, image, {
    headers: {
      'Content-Type': image.type,
    },
  });
  return response;
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });

export const getTimeFromMins = (mins) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h ${minutes}m`;
};

export const getBase64File = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader?.addEventListener('load', () => callback(reader?.result));
  reader?.readAsDataURL(img);
};

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    toast({
      closeicn: 'destructive',
      description: 'You can only upload JPG/PNG file!',
    });
  }
  const isLt2M = file?.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    toast({
      closeicn: 'destructive',
      description: 'Image must smaller than 5MB!',
    });
  }
  return isJpgOrPng && isLt2M;
};

export function download(filename, htmlContent) {
  // Create a new DOMParser to parse the HTML content

  const doctype = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">`;
  const fullHtmlContent = `${doctype}
  <html>
  <head></head>
  <body>${htmlContent}</body>
  </html>`;

  const parser = new DOMParser();
  const doc = parser.parseFromString(fullHtmlContent, 'text/html');

  // Create and append the meta tags to the head
  const metaCharset = document.createElement('meta');
  metaCharset.setAttribute('http-equiv', 'Content-Type');
  metaCharset.setAttribute('content', 'text/html; charset=UTF-8');
  doc.head.appendChild(metaCharset);

  const metaViewport = document.createElement('meta');
  metaViewport.setAttribute('name', 'viewport');
  metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
  doc.head.appendChild(metaViewport);

  // Create and append the title tag
  const title = document.createElement('title');
  title.textContent = 'Email Signature';
  doc.head.appendChild(title);

  // Serialize the modified HTML content
  const modifiedHtmlContent = new XMLSerializer().serializeToString(doc);

  // Create a download link
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:text/html;charset=utf-8,${encodeURIComponent(modifiedHtmlContent)}`,
  );
  element.setAttribute('download', filename);
  element.style.display = 'none';

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function HexCode(color) {
  const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
  const hex = `#${
    // eslint-disable-next-line no-bitwise
    (
      (1 << 24) +
      // eslint-disable-next-line no-bitwise
      (parseInt(rgba[0], 10) << 16) +
      // eslint-disable-next-line no-bitwise
      (parseInt(rgba[1], 10) << 8) +
      parseInt(rgba[2], 10)
    )
      .toString(16)
      .slice(1)
  }`;

  return hex;
}
export const openNotification = (type, msg) => {
  // notification[type]({
  //   message: msg,
  //   placement: 'topRight',
  //   duration: 5,
  // });
  toast({
    description: msg,
    duration: 5,
    className: 'top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4',
    variant: 'default',
  });
};

const imageStyle = {
  display: 'flex',
  height: '100%',
  width: '100%',
};

export const addIconsInLinks = () =>
  map(SOCIAL_LINKS, (link) => {
    switch (link?.option) {
      case SOCIAL_LINKS?.TIKTOK?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="tik-tok-icon"
            />
          ),
        };

      case SOCIAL_LINKS?.PINTEREST?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="pinterest circle"
            />
          ),
        };

      case SOCIAL_LINKS?.VIMEO?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="vimeo circle"
            />
          ),
        };

      case SOCIAL_LINKS?.GITHUB?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="github circle"
            />
          ),
        };
      case SOCIAL_LINKS?.MEDIUM?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="medium circle"
            />
          ),
        };
      case SOCIAL_LINKS?.PODCAST?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="podcast circle"
            />
          ),
        };
      case SOCIAL_LINKS?.SKYPE?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="skype circle"
            />
          ),
        };
      case SOCIAL_LINKS?.TRIPADVISOR?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="tripadvisor circle"
            />
          ),
        };
      case SOCIAL_LINKS?.PATREON?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="patreon circle"
            />
          ),
        };
      case SOCIAL_LINKS?.FLICKER?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="flickr circle"
            />
          ),
        };
      case SOCIAL_LINKS?.IMDB?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="IMDB circle"
            />
          ),
        };
      case SOCIAL_LINKS?.RARIBLE?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="rarible circle"
            />
          ),
        };
      case SOCIAL_LINKS?.NEXTDOOR?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="nextdoor circle"
            />
          ),
        };
      case SOCIAL_LINKS?.BEHANCE?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="behance circle"
            />
          ),
        };
      case SOCIAL_LINKS?.ANGI?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="angi circle"
            />
          ),
        };
      case SOCIAL_LINKS?.LINKTREE?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="linktree circle"
            />
          ),
        };
      case SOCIAL_LINKS?.FACEBOOK?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="facebook circle"
            />
          ),
        };
      case SOCIAL_LINKS?.INSTAGRAM?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="instagram circle"
            />
          ),
        };
      case SOCIAL_LINKS?.LINKEDIN?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="linkdin circle"
            />
          ),
        };
      case SOCIAL_LINKS?.YOUTUBE?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="youtube circle"
            />
          ),
        };
      case SOCIAL_LINKS?.TWITTER?.option:
        return {
          ...link,
          Icon: (
            <img
              src={socialImages?.[link.key]}
              style={imageStyle}
              width={imageStyle.width}
              alt="x circle"
            />
          ),
        };
      default:
        break;
    }
  });

export function removeHttpPrefix(inputString) {
  // Use a regular expression to remove "https://" or "http://"
  const resultString = inputString?.replace(/^(https?:\/\/)/, '');
  return resultString;
}

export const handleUrlRedirection = (input) => {
  const string = String(input);

  return string.startsWith('http') ? string : `https://${string}`;
};

export const supportedFileType = (file) =>
  ['image/jpeg', 'image/jpg', 'image/png', 'image/heic']?.includes(file?.type);

export const unSupportedSize = (file) => file?.size > ALLOWED_IMAGE_SIZE;

export const imageValidator = (file) => {
  const isImage = file?.type?.startsWith('image/');
  const isSupportType = supportedFileType(file);
  const isUnSupportSize = unSupportedSize(file);
  if (file) {
    if (!isImage) {
      toast({
        closeicn: 'destructive',
        description: 'You can upload only image Files!',
      });
      return false;
    }
    if (isImage && !isSupportType) {
      toast({
        closeicn: 'destructive',
        description: 'File type not supported',
      });
      return false;
    }
    if (isUnSupportSize) {
      toast({
        closeicn: 'destructive',
        description: 'You can upload image up to 15 MB of size',
      });
    }
    return false;
  }
};

export const getUserInitials = (userName) => {
  if (userName) {
    const nameParts = userName
      .split(' ')
      ?.filter((part) => part?.trim() !== '');
    const firstLetter = nameParts[0]?.[0]?.toUpperCase();
    const secondLetter = nameParts[1]?.[0]?.toUpperCase();
    const initials = secondLetter
      ? `${firstLetter}${secondLetter}`
      : firstLetter;
    return initials;
  }
};

export function getBase64SizeInKB(base64String) {
  const base64WithoutHeader = base64String?.split(',')?.[1];
  const stringLength = base64WithoutHeader?.length;
  const sizeInKB = (stringLength * 0.75) / 1024;
  return sizeInKB?.toFixed(2);
}

export const progressBarPercent = (count, totalCount) => {
  const getPercent = Math?.abs((count / totalCount) * 100)?.toFixed(
    count === totalCount ? 0 : 1,
  );
  return getPercent;
};

export function filterValidURLs(obj) {
  return Object?.keys(obj)?.reduce((result, key) => {
    const url = obj?.[key]?.[Object?.keys(obj?.[key])?.[0]];
    try {
      // eslint-disable-next-line no-new
      new URL(url);
      // eslint-disable-next-line no-param-reassign
      result[key] = obj?.[key];
    } catch (error) {
      return;
    }
    return result;
  }, {});
}

export const URL_REGEX =
  /^https:\/\/([\da-z.-]+)\.([a-z.]{2,6})([/\w .-@]*)*\/?(#[\w-]*)?$/i;

export const isValidUrl = (url) => {
  const urlPattern = new RegExp(URL_REGEX);
  return urlPattern.test(url);
};

export const checkValidEmail = (email) => {
  const emailRegex = REGEX?.EMAIL;
  return emailRegex?.test(email);
};

export function filterValidLinks(socialLinks) {
  // // Filter out invalid links
  const filteredData = {};

  Object?.keys(socialLinks)?.forEach((key) => {
    const socialMedia = socialLinks?.[key];
    const validLinks = {};

    Object?.keys(socialMedia)?.forEach((platform) => {
      const link = socialMedia[platform];

      // Check if the link is valid
      if (isValidURL(link)) {
        validLinks[platform] = link;
      } else {
        validLinks[platform] = '';
      }
    });

    // Add valid links to the filteredData object
    if (Object?.keys(validLinks)?.length > 0) {
      filteredData[key] = validLinks;
    }
  });
  return filteredData;
}

export function cloneAndRemoveTypename(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(cloneAndRemoveTypename);
  }

  const newObj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    if (key !== '__typename') {
      newObj[key] = cloneAndRemoveTypename(value);
    }
  }
  return newObj;
}

export function dataURLtoFile(base64, filename) {
  // Validate input dataurl
  if (
    !base64 ||
    !filename ||
    !base64.startsWith('data:') ||
    base64.indexOf('base64,') === -1
  ) {
    return null;
  }

  try {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  } catch (error) {
    return null;
  }
}

const handleImageUpload = async (
  baseUrl,
  fileName,
  signatureId,
  dimensions,
) => {
  if (!baseUrl) return;
  try {
    const {
      data: { getSignatureIconSignedUrl },
    } = await client.query({
      query: GET_SIGNATURE_ICON_SIGNED_URL,
      variables: { data: { fileName, signatureId } },
      fetchPolicy: 'no-cache',
    });
    const file = dataURLtoFile(baseUrl, getSignatureIconSignedUrl.key);
    const uploadResponse = await fileUpload(
      getSignatureIconSignedUrl.signedUrl,
      file,
    );
    const key = uploadResponse?.config?.data?.name;

    const awsUrl = getLiveUrl(key);
    return { key: fileName, url: awsUrl, ...dimensions };
    // setImageUrl(`${imageUrlRes}?timestamp=${new Date().getTime()}`);
  } catch (error) {
    return { key: fileName, url: baseUrl, ...dimensions };
  }
};

export async function uploadAllImages(items, signatureId) {
  try {
    const uploadPromises = items.map((item) =>
      handleImageUpload(item.url, item.key, signatureId, {
        height: item.height,
        width: item.width,
      }),
    );
    const results = await Promise.all(uploadPromises);
    return results;
  } catch (error) {
    return error;
    // Handle any errors that occurred during upload
  }
}

export const updateProperties = (obj, updates, removals = []) => {
  const result = { ...obj };

  // Handle updates
  Object.keys(updates).forEach((path) => {
    const value = updates[path];
    const keys = path.split('.');
    let current = result;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key] = value;
      } else {
        if (!current[key] || typeof current[key] !== 'object') {
          current[key] = {}; // Initializing a new object if not already an object
        }
        current = current[key];
      }
    });
  });

  // Handle removals
  removals.forEach((path) => {
    const keys = path.split('.');
    let current = result;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        delete current[key]; // Remove the property
      } else {
        if (!current[key]) {
          return; // If the path does not exist, no need to continue
        }
        current = current[key];
      }
    });
  });

  return result;
};

export const hardReaload = async (shouldReload = true) => {
  // eslint-disable-next-line no-undef
  await caches.keys().then((names) =>
    Promise.all(
      // eslint-disable-next-line no-undef
      names.map((name) => caches.delete(name)),
    ),
  );
  if (shouldReload) {
    // eslint-disable-next-line no-undef
    window.location.reload(true);
  }
};

export function isImgUrl(url) {
  // eslint-disable-next-line no-undef
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
}

export const getFieldData = (fields, name) => {
  const res = fields?.find((field) => field?.name === name);
  if (res?.value) {
    return res;
  }
  return null;
};

export const getMatchingFields = (fields1, fields2) => {
  if (!fields1 || !fields2) return [];
  // Create a Set for fast lookups
  const fields2Set = new Set(
    fields2.filter((field) => field?.value).map((field) => field?.name),
  );
  // Filter fields1 based on the existence in fields2Set
  return fields1.filter((field1) => fields2Set.has(field1?.name));
};

export const handleCopy = (contentRef) => {
  const contentToCopy = contentRef?.current;
  if (contentToCopy) {
    // eslint-disable-next-line no-undef
    const range = document?.createRange();
    range?.selectNode(contentToCopy);
    // eslint-disable-next-line no-undef
    window?.getSelection()?.removeAllRanges();
    // eslint-disable-next-line no-undef
    window?.getSelection()?.addRange(range);

    try {
      // eslint-disable-next-line no-undef
      document?.execCommand('copy');
      toast({
        closeicn: 'success',
        description: 'Email signature copied to clipboard!',
      });
      // message?.success('Email signature copied to clipboard!');
    } catch (err) {
      // message?.error('Please try again');
      toast({
        closeicn: 'destructive',
        description: 'Please try again',
      });
    }
    // eslint-disable-next-line no-undef
    window?.getSelection()?.removeAllRanges();
  }
};

export const getBaseURL = () => window.location.origin;

export const isMatchingURL = () => {
  const currentBaseURL = getBaseURL();
  const allowedURLs = [
    'https://app.dev.syncsignature.com',
    'https://app.syncsignature.com',
    'http://localhost:3000',
  ];
  return allowedURLs.includes(currentBaseURL);
};

export const isEmptyObject = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const resizeImage = (file, width) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        // Calculate the aspect ratio
        const aspectRatio = img.height / img.width;
        const height = width * aspectRatio;

        // Create a canvas element to resize the image
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0, width, height);

        // Convert the canvas to a Blob
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              resolve(resizedFile);
            } else {
              reject(new Error('Failed to create blob from canvas.'));
            }
          },
          file.type,
          1,
        );
      };
      img.src = event.target.result;
    };

    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

export const formatDateTime = (dateTimeString) => {
  // Create a Date object from the input date-time string
  const dateObj = new Date(dateTimeString);

  // Extract components and format them
  const year = dateObj.getUTCFullYear();
  const month = dateObj.toLocaleString('en-US', { month: 'long' }); // e.g., "September"
  const day = dateObj.getUTCDate();

  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();

  // Format hours in 12-hour format and determine AM/PM
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format

  // Format minutes (add leading zero if necessary)
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Create formatted date string
  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
};
