import React from 'react';
import { get } from 'lodash';

function Name({ style = {}, tdProps = {}, signature = {}, data = {} }) {
  const { font } = get(signature, 'design.styles', {});
  const { headerFont } = get(signature, 'design.styles', {});
  const { fontSize, lineHeight } = get(font, 'style', {});

  const textStyle = {
    fontSize: headerFont?.style?.fontSize || fontSize,
    lineHeight,
    color: signature?.design?.primaryBrandColor,
  };

  return (
    <td
      style={{
        fontWeight: 700,
        paddingBottom: '2px',
        ...textStyle,
        ...style,
      }}
      {...tdProps}
    >
      {data?.value}
    </td>
  );
}

export default Name;
