import React from 'react';
import get from 'lodash/get';

const Divider = ({
  style = {},
  signature = {},
  design = {},
  colSpan = 2,
  horizontalBorder = true,
  verticalBorder = false,
}) => {
  const width = get(design, 'styles.separator.style.width', 1);
  return (
    <td
      colSpan={colSpan}
      style={{
        borderTop: horizontalBorder
          ? `${width}px solid ${signature?.design?.primaryBrandColor}`
          : 'none',
        borderRight: verticalBorder
          ? `${width}px solid ${signature?.design?.primaryBrandColor}`
          : 'none',
        paddingBottom: '8px',

        ...style,
      }}
    />
  );
};

export default Divider;
