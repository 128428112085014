/* eslint-disable react/jsx-filename-extension */
import { useMutation } from '@apollo/client';
import React from 'react';
import { PRIMARY_COLOR } from 'common/constants';
import {
  CREATE_HEADSHOT,
  REMOVE_HEADSHOT,
} from 'modules/Headshot/graphql/Mutations';
import { UPSERT_SIGNATURE } from 'modules/Signature/graphql/Mutations';
import ImageUploadWithCrop from 'components/ImageUploadWithCrop';
import { useAppContext } from 'AppContext';
import { useSignatureId } from 'hooks/signature';
import { useWorkspaceId } from 'hooks/workspace';

function CustomImageUploader({ noImageUploadedTitle = '' }) {
  const {
    state: { signature },
    setHeadshot,
    setHeadshotUpdateStatus,
    setSignature,
  } = useAppContext();

  const { signatureId } = useSignatureId();
  const { workspaceId } = useWorkspaceId();
  const [upsertSignature] = useMutation(UPSERT_SIGNATURE, {
    onCompleted() {},
    onError() {},
  });

  const [createHeadShot] = useMutation(CREATE_HEADSHOT, {
    onCompleted(res) {
      const { __typename, updatedAt, createdAt, ...restData } =
        res?.createHeadshot?.data || {};
      setHeadshot({
        ...restData,
      });
      const { id, ...signatureData } = signature;
      upsertSignature({
        variables: {
          data: {
            ...signatureData,
            headshotId: restData?.id,
          },
          where: {
            signatureId, // signature id for MUTATION_control
            workspaceId,
          },
        },
      });
      setHeadshotUpdateStatus(true);
    },
    onError() {},
  });

  const [removeHeadshot] = useMutation(REMOVE_HEADSHOT, {
    onCompleted: async () => {
      setHeadshot({
        config: {
          'background-color': PRIMARY_COLOR,
        },
      });
      const { id, ...restData } = signature;
      await upsertSignature({
        variables: {
          data: {
            ...restData,
            headshotId: null,
          },
          where: {
            signatureId: id,
            workspaceId,
          },
        },
      });
      setSignature({ ...signature, headshotId: null });
      setHeadshotUpdateStatus(true);
    },
    onError() {},
  });

  return (
    <div>
      <ImageUploadWithCrop
        removeHeadshot={removeHeadshot}
        noImageUploadedTitle={noImageUploadedTitle}
        createHeadShot={createHeadShot}
      />
    </div>
  );
}

export default CustomImageUploader;
