/* eslint-disable arrow-body-style */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button } from 'components/ui/button';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog';

const SaveSignatureDialog = forwardRef(({ handleSaveChanges }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogContent className="rounded-[6px] max-w-md" hideCloseButto>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-left m-0">
            You may have some unsaved changes.
          </AlertDialogTitle>
          <AlertDialogDescription className="text-left m-0">
            Your changes are not saved yet. Please save them before you leave
            the page.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <div className="flex justify-end gap-3 pt-4">
            <Button
              className="w-[max-content]"
              onClick={() => setIsOpen(false)}
              variant="outline"
            >
              Cancel
            </Button>
            <Button onClick={() => handleSaveChanges()}>Save</Button>
          </div>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
});

export default SaveSignatureDialog;
