import { ChevronRight, Zap } from 'lucide-react';
import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { get, isString } from 'lodash';
import { useAppContext } from 'AppContext';
import { useRouter } from 'hooks/router';
import { PRIMARY_COLOR } from 'common/constants';
import { GET_STYLES } from 'modules/Headshot/graphql/Queries';
import LoginModal from 'components/LoginModal';
import HeadshotList from 'components/HeadshotList';
import ColorPicker from 'components/ColorPicker';
import Button from 'components/Button';
import AiHeadshotButton from 'components/AiHeadshotButton';
import { useWindowSize } from 'helpers/useWindowSize';

const PublicHeadshotEditor = () => {
  const {
    state: { signature, headshot },
    setHeadshot,
  } = useAppContext();

  const primaryBrandColor = get(signature, 'design.primaryBrandColor', {});

  const { navigate } = useRouter();
  const { size } = useWindowSize();
  const [primaryColor] = useState(primaryBrandColor || PRIMARY_COLOR);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-undef

  const { data: { styles: { data: stylesData } = {} } = {} } = useQuery(
    GET_STYLES,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (!headshot?.id) {
      navigate('/public-headshot');
    }
  }, [headshot?.id]);

  const handleSelect = async (ref) => {
    const { __typename, ...selectedStyle } = stylesData?.find(
      (d) => d.key === ref.current.id,
    );

    setHeadshot({
      ...headshot,
      style: {
        ...headshot?.style,
        ...selectedStyle,
      },
      styleId: selectedStyle?.id,
    });
  };

  return (
    <div className="min-h-screen h-full max-h-screen">
      {/* {!['xs', 'sm'].includes(size) && (
        <div className='h-[36px] max-w-full px-[24px] py-[16px] bg-[#1F192B] text-white-0  font-[600] text-[22px] leading-[36px] m-auto flex justify-center items-center'>
          Experience the taste of AI-headshots for free! ✨{' '}
          <AiHeadshotButton onClick={()=>{navigate(`/auth/signup?r=ai-headshot`)}} className=' ml-4 flex h-[32px] px-[20px] justify-center bg-gradient-to-r from-[#8557E9] via-[#BC57E6] to-[#F357E3]'>
            Try for free
          </AiHeadshotButton>
        </div>
      )} */}
      {open && <LoginModal open={open} setOpen={setOpen} />}
      <div className="h-screen w-full bg-primary-foreground flex-col table">
        <div className=" py-40 px-24 md:p-24 sm:py-12 sm:px-6 bg-primary-foreground flex-col table-cell  ">
          {/* <header className="mb-[60px] 2xl:mb-[20px] flex items-center gap-[6px] ">
            <img src="/logo.svg" height={40} width={200} alt="logo" />
          </header> */}

          <section className="headshot-preview-section flex items-center justify-center mb-[60px]">
            <div className="max-w-[850px] w-full">
              <HeadshotList
                colorPicked={
                  headshot?.config?.['background-color'] || primaryColor
                }
                svgColor="white"
                rounded
                stylesData={stylesData}
                clickable
                handleSelect={handleSelect}
              />
            </div>
          </section>

          <section className="headshot-control-panel flex justify-center gap-4">
            <div className="flex justify-center gap-1">
              <ColorPicker
                hideApplyButton
                value={headshot?.config?.['background-color'] || primaryColor}
                onChange={(value) => {
                  const color = isString(value) ? value : `#${value?.toHex()}`;

                  setHeadshot({
                    ...headshot,
                    config: {
                      ...headshot?.config,
                      'background-color': color,
                    },
                  });
                }}
                className="signature-color-picker"
                isPresetShow
              />
            </div>
          </section>
          <section className="flex justify-center">
            <Button onClick={() => setOpen(true)}>
              <div className="flex items-center justify-center space-x-1 w-[200px]">
                <span>Download</span>
                <ChevronRight width="20" height="20" />
              </div>
            </Button>
          </section>
        </div>
      </div>
      {/* {['xs', 'sm'].includes(size) && (
        <div className="flex justify-center w-full bg-primary-foreground">
          <AiHeadshotButton className="flex justify-center bg-gradient-to-r from-[#8557E9] via-[#BC57E6] to-[#F357E3]">
            Try AI Headshot
            <Zap size={16} className="ml-[6px] fill-white-0" />
          </AiHeadshotButton>
        </div>
      )} */}
    </div>
  );
};

export default PublicHeadshotEditor;
