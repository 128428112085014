import { getTemplateImages } from 'common/constants';

const Template1 = [
  {
    id: '0da41bb4-1dfa-41a5-8171-f9bf5bc680a4',
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_1'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Samantha Green',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'Senior Marketing Manager',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: 'Marketing',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: 'Green Tech solution',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '984 Penn Rd. NY 102',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'companylogo1',
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: getTemplateImages('companylogo_TEMPLATE_1'),
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value:
          'The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 's@greentech.com',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 's@greentech.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: ' 212-232-3233',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'https://greentech.com',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: 'https://greentech.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: '',
        value: 'https://facebook.com/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: 'https://www.instagram.com/#',
        isVariable: false,
        shortLink: '',
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: 'https://www.linkedin.com/company/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      // {
      //   link: 'https://twitter.com/#',
      //   name: 'twitter',
      //   type: 'company',
      //   label: 'TWITTER',
      //   value: 'https://twitter.com/#',
      //   shortLink: '',
      //   isVariable: false,
      //   utagEnabled: true,
      //   variableValue: null,
      // },
      {
        name: 'socialBanners',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],
    design: {
      primaryBrandColor: '#56B7FF',
      font: 'Arial, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: [
        {
          key: 'phone',
          url: 'https://static.dev.sendsig.com/icons/7984e5f5-c4ff-4d60-b53d-77e7f01a93b4/phone-icon-9e71c280-7046-42dc-a456-756db9661d39.png?timestamp=1728303697158',
          width: 16,
          height: 16,
        },
        {
          key: 'website',
          url: 'https://static.dev.sendsig.com/icons/7984e5f5-c4ff-4d60-b53d-77e7f01a93b4/website-icon-9bfddbb1-5495-4d6f-985d-e837fd076904.png?timestamp=1728303697147',
          width: 16,
          height: 16,
        },
        {
          key: 'address',
          url: 'https://static.dev.sendsig.com/icons/7984e5f5-c4ff-4d60-b53d-77e7f01a93b4/address-icon-82b10bb7-696b-42aa-899f-a775425bf902.png?timestamp=1728303697148',
          width: 16,
          height: 16,
        },
        {
          key: 'email',
          url: 'https://static.dev.sendsig.com/icons/7984e5f5-c4ff-4d60-b53d-77e7f01a93b4/email-icon-8ee8e74c-8b05-4054-9a30-d2039ff15b08.png?timestamp=1728303697166',
          width: 16,
          height: 16,
        },
        {
          key: 'facebook',
          url: 'https://static.dev.sendsig.com/icons/7984e5f5-c4ff-4d60-b53d-77e7f01a93b4/facebook-icon-289be6e8-6ed4-4a62-85c8-cce2975f3b8b.png?timestamp=1728372457485',
          width: 29,
          height: 29,
        },
        {
          key: 'instagram',
          url: 'https://static.dev.sendsig.com/icons/7984e5f5-c4ff-4d60-b53d-77e7f01a93b4/instagram-icon-b06d310f-858b-4a8e-958b-3c27612ced9a.png?timestamp=1728372457480',
          width: 29,
          height: 29,
        },
        {
          key: 'linkedin',
          url: 'https://static.dev.sendsig.com/icons/7984e5f5-c4ff-4d60-b53d-77e7f01a93b4/linkedin-icon-0fce1714-fe06-40e8-96dc-acee79d39d5a.png?timestamp=1728372457520',
          width: 29,
          height: 29,
        },
      ],
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 115,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 13,
            lineHeight: 1.09,
          },
        },
        headerFont: {
          style: {
            fontSize: 13,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          style: {
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 150,
          },
        },
        ctaBanner: {
          style: {
            width: 250,
            ctaborderRadius: 0,
          },
        },
        headshot: {
          style: {
            width: 98,
            borderRadius: 50,
          },
        },
        signoff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            width: 1,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '95f62733-3d7f-4fc5-a2b5-d86b616d10b6',
    headshotId: null,
    headshot: {},
    template: {
      id: '95f62733-3d7f-4fc5-a2b5-d86b616d10b6',
      name: 'template 1',
      image: null,
      key: 'TEMPLATE_1',
      description: null,
      isActive: true,
      config: null,
      templateId: 1,
    },
    name: 'My Signature 1',
  },
];

export default Template1;
