import { socialImages } from 'assets/social-images';
import { SOCIAL_LINKS } from 'common/constants';
import WithImageConversion from 'common/helpers/htmlToImage';
import { handleUrlRedirection } from 'common/utils';
import { get, map } from 'lodash';
import React from 'react';

const SocialLinkTypeWrapper = ({
  type,
  styles,
  children,
  filename,
  signature,
  showAwsIcons,
}) => {
  const child = {
    rounded: children,
    square: children,
  };

  const updatedStyle = {
    rounded: { borderRadius: '50%' },
    square: { borderRadius: '5px' },
  };

  const content = (
    <p
      style={{
        ...styles,
        ...(updatedStyle[type] || {}),
        marginTop: '1px',
        marginBottom: '1px',
      }}
    >
      {child[type] || ''}
    </p>
  );

  // Only wrap WithImageConversion for 'rounded' and 'square' types
  if (['rounded', 'square'].includes(type)) {
    return (
      <WithImageConversion
        signature={signature}
        showAwsIcons={showAwsIcons}
        key={filename}
        fileName={filename}
      >
        {content}
      </WithImageConversion>
    );
  }
  return content;
};
function SocialLinks({
  style = {},
  design,
  tdProps = {},
  alignment = 'horizontal',
  signature = {},
  data = [],
  showAwsIcons = false,
}) {
  const { styles } = get(signature, 'design', {});
  const type = get(styles, 'socialIcon.type', 'rounded');
  const size = get(styles, 'socialIcon.style.size', 16);

  return (
    <td
      style={{
        ...style,
        paddingBottom: '8px',
      }}
      {...tdProps}
    >
      <table
        cellPadding="0"
        cellSpacing="0"
        style={{
          borderCollapse: 'collapse',
          fontSize: 'inherit',
        }}
      >
        <tbody>
          {alignment === 'horizontal' ? (
            <tr>
              {map(data, (social, index) => (
                <td
                  style={{
                    ...(index !== data.length - 1 && { paddingRight: '8px' }),
                    ...(!social?.value && { display: 'none' }),
                  }}
                  key={`${social?.value}-${index}`}
                >
                  <a href={social?.value}>
                    <SocialLinkTypeWrapper
                      signature={signature}
                      showAwsIcons={showAwsIcons}
                      filename={social?.name}
                      type={type}
                      styles={{
                        height: size,
                        width: size,
                        backgroundColor: design?.primaryBrandColor,
                        display: 'block',
                      }}
                    >
                      <img
                        src={
                          socialImages?.[
                            SOCIAL_LINKS.find((s) => s.name === social?.name)
                              .key
                          ]
                        }
                        style={{
                          display: 'flex',
                          height: '100%',
                          width: '100%',
                        }}
                        width="100%"
                        alt="tik-tok-icon"
                      />
                    </SocialLinkTypeWrapper>
                  </a>
                </td>
              ))}
            </tr>
          ) : (
            map(data, (social, index) => (
              <tr>
                <td
                  style={{
                    paddingBottom: '3px',
                    ...(!social?.value && { display: 'none' }),
                  }}
                  key={`${social?.value}-${index}`}
                >
                  <a href={handleUrlRedirection(social?.value)}>
                    <SocialLinkTypeWrapper
                      signature={signature}
                      filename={social?.name}
                      type={type}
                      styles={{
                        height: size,
                        width: size,
                        backgroundColor: design?.primaryBrandColor,
                        display: 'block',
                      }}
                    >
                      <img
                        src={
                          socialImages?.[
                            SOCIAL_LINKS.find((s) => s.name === social?.name)
                              .key
                          ]
                        }
                        style={{
                          display: 'flex',
                          height: '100%',
                          width: '100%',
                        }}
                        width="100%"
                        alt="tik-tok-icon"
                      />
                    </SocialLinkTypeWrapper>
                  </a>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </td>
  );
}

export default SocialLinks;
