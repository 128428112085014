/* eslint-disable no-nested-ternary */
import { socialImages } from 'assets/social-images';
import { TEXT_IDS } from 'common/constants';
import WithImageConversion from 'common/helpers/htmlToImage';
import { get } from 'lodash';
import React from 'react';

const EmailTypeWrapper = ({
  type,
  styles,
  iconDimensions,
  children,
  signature,
  showAwsIcons,
}) => {
  const { fontSize, lineHeight } = styles;
  const child = {
    letter: 'E.',
    word: 'Email.',
    rounded: children,
    square: children,
    none: '',
  };

  const updatedStyle = {
    letter: {
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
      width: 'auto',
      maxWidth: '100%',
      fontSize,
      lineHeight,
    },
    word: {
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
      width: 'auto',
      maxWidth: '100%',
      fontSize,
      lineHeight,
    },
    rounded: { borderRadius: '50%', ...iconDimensions },
    square: { borderRadius: '5px', ...iconDimensions },
    none: { display: 'none' },
  };

  const content = (
    <p
      style={{
        ...styles,
        ...(updatedStyle[type] || {}),
        margin: '0px',
        padding: '0px',
      }}
    >
      {child[type] || ''}
    </p>
  );

  // Only wrap WithImageConversion for 'rounded' and 'square' types
  if (['rounded', 'square'].includes(type)) {
    return (
      <WithImageConversion
        signature={signature}
        showAwsIcons={showAwsIcons}
        key="email"
        fileName="email"
      >
        {content}
      </WithImageConversion>
    );
  }
  return content;
};

function Email({
  design,
  data,
  signature = {},
  style = {},
  tdProps = {},
  showAwsIcons = false,
}) {
  const { icon, font } = get(signature, 'design.styles', {});
  const { fontSize, lineHeight, color } = get(font, 'style', {});

  const type = get(icon, 'type', 'rounded');
  const size = fontSize + 4;

  const textStyle = {
    fontSize,
    lineHeight: type === 'letter' || type === 'word' ? lineHeight : '',
    color,
  };

  return (
    <td
      style={{
        ...style,
      }}
      {...tdProps}
    >
      <table cellPadding="0" cellSpacing="0" border="0">
        <tbody>
          <tr>
            <td
              valign="middle"
              style={{
                paddingRight: type === 'none' ? '0px' : '6px',
                alignItems: 'center',
                paddingBottom: '6px',
                display: 'flex',
              }}
            >
              <EmailTypeWrapper
                signature={signature}
                showAwsIcons={showAwsIcons}
                type={type}
                iconDimensions={{ height: size, width: size }}
                styles={{
                  height:
                    type === 'letter' || type === 'word' ? 'auto' : '16px', // Conditional height
                  width: '16px',
                  fontSize,
                  backgroundColor: design?.primaryBrandColor,

                  verticalAlign: 'middle',
                }}
              >
                <img
                  src={socialImages?.['email-circle']}
                  alt="email"
                  style={{
                    height: '100%',
                    width: '100%',

                    verticalAlign: 'middle',
                  }}
                />
              </EmailTypeWrapper>
              <a
                id={TEXT_IDS.EMAIL}
                valign="middle"
                href={data?.shortLink || `mailto:${data?.value}`}
                target="_blank"
                style={{
                  ...textStyle,
                  textAlign: 'left',
                  textDecoration: 'none',
                  paddingLeft: '4px',
                  verticalAlign: 'middle',
                  whiteSpace: 'nowrap',
                }}
                rel="noreferrer"
              >
                {data?.value}
              </a>
            </td>
            {/* <td
              style={{
                ...textStyle,
              }}
            >
              <a
                id={TEXT_IDS.EMAIL}
                valign="middle"
                href={`mailto:${data?.value}`}
                target="_blank"
                style={{
                  ...textStyle,
                  textAlign: 'left',
                  textDecoration: 'none',
                }}
                rel="noreferrer"
              >
                {data?.value}
              </a>
            </td> */}
          </tr>
        </tbody>
      </table>
    </td>
  );
}

export default Email;
