/* eslint-disable import/prefer-default-export */
import AngiCircle from './angi-circle.png';
import EmailCircle from './email-circle.png';
import FacebookCircle from './facebook-circle.png';
import FlickrCircle from './flickr-circle.png';
import GithubCircle from './github-circle.png';
import IMDBCircle from './IMDB-circle.png';
import InstagramCircle from './instagram-circle.png';
import LinkCircle from './link-circle.png';
import LinkdinCircle from './linkdin-circle.png';
import LinksCircle from './links-circle.png';
import LinktreeCircle from './linktree-circle.png';
import LocationCircle from './location-circle.png';
import MediumCircle from './medium-circle.png';
import NextdoorCircle from './nextdoor-circle.png';
import PatreonCircle from './patreon-circle.png';
import PhoneCircle from './phone-circle.png';
import PinterestCircle from './pinterest-circle.png';
import PodcastCircle from './podcast-circle.png';
import RaribleCircle from './rarible-circle.png';
import Rectangle1 from './Rectangle 1.png';
import SkypeCircle from './skype-circle.png';
import TikTokCircle from './tik-tok-circle.png';
import TripadvisorCircle from './tripadvisor-circle.png';
import VimeoCircle from './vimeo-circle.png';
import XCircle from './x-circle.png';
import YelpCircle from './yelp-circle.png';
import YoutubeCircle from './youtube-circle.png';
import BehanceCircle from './behance-circle.png';

export const socialImages = {
  'angi-circle': AngiCircle,
  'email-circle': EmailCircle,
  'facebook-circle': FacebookCircle,
  'flickr-circle': FlickrCircle,
  'flicker-circle': FlickrCircle,
  'github-circle': GithubCircle,
  'IMDB-circle': IMDBCircle,
  'imdb-circle': IMDBCircle,
  'instagram-circle': InstagramCircle,
  'link-circle': LinkCircle,
  'linkdin-circle': LinkdinCircle,
  'linkedin-circle': LinkdinCircle,
  'links-circle': LinksCircle,
  'linktree-circle': LinktreeCircle,
  'location-circle': LocationCircle,
  'medium-circle': MediumCircle,
  'nextdoor-circle': NextdoorCircle,
  'patreon-circle': PatreonCircle,
  'phone-circle': PhoneCircle,
  'pinterest-circle': PinterestCircle,
  'podcast-circle': PodcastCircle,
  'rarible-circle': RaribleCircle,
  'Rectangle 1': Rectangle1,
  'skype-circle': SkypeCircle,
  'tik-tok-circle': TikTokCircle,
  'tiktok-circle': TikTokCircle,
  'tripadvisor-circle': TripadvisorCircle,
  'vimeo-circle': VimeoCircle,
  'x-circle': XCircle,
  'twitter-circle': XCircle,
  'yelp-circle': YelpCircle,
  'youtube-circle': YoutubeCircle,
  'behance-circle': BehanceCircle,
};
