import { getTemplateImages } from 'common/constants';

const Template13 = [
  {
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_13'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'David Johns',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'Creative Director',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: 'Microsoft',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'davidjohns@gmail.com',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'davidjohns@gmail.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '(345) 087-1239',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'https://www.website.com',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: 'https://www.website.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: '',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: '',
        value: 'https://facebook.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: 'https://www.instagram.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: 'https://www.linkedin.com/company/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      // {
      //   link: 'https://twitter.com/#',
      //   name: 'twitter',
      //   type: 'company',
      //   label: 'TWITTER',
      //   value: 'https://twitter.com/#',
      //   isVariable: false,
      //   utagEnabled: true,
      //   variableValue: null,
      // },
      {
        name: 'socialBanners',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],

    design: {
      primaryBrandColor: '#F8CA0B',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: [
        {
          key: 'phone',
          url: 'https://static.dev.sendsig.com/icons/b06618fb-859f-45b8-b327-ef31d32d5156/phone-icon-852154eb-da59-4b0a-90b2-f5214d40d52e.png?timestamp=1728726374562',
          width: 16,
          height: 16,
        },
        {
          key: 'email',
          url: 'https://static.dev.sendsig.com/icons/b06618fb-859f-45b8-b327-ef31d32d5156/email-icon-9efcb4c5-22d6-46df-9f5e-5eeba033a893.png?timestamp=1728726374464',
          width: 16,
          height: 16,
        },
        {
          key: 'website',
          url: 'https://static.dev.sendsig.com/icons/b06618fb-859f-45b8-b327-ef31d32d5156/website-icon-3f268472-e0b4-4ef9-b27f-9797c9d2b0ee.png?timestamp=1728726374545',
          width: 16,
          height: 16,
        },
        {
          key: 'address',
          url: 'https://static.dev.sendsig.com/icons/b06618fb-859f-45b8-b327-ef31d32d5156/address-icon-29c2b7c2-08ca-499b-994e-4086c7a34f58.png?timestamp=1728726374472',
          width: 16,
          height: 16,
        },
        {
          key: 'facebook',
          url: 'https://static.dev.sendsig.com/icons/b06618fb-859f-45b8-b327-ef31d32d5156/facebook-icon-18c6c2be-ef22-4253-b3b2-b56d60176dfd.png?timestamp=1728726374483',
          width: 20,
          height: 20,
        },
        {
          key: 'instagram',
          url: 'https://static.dev.sendsig.com/icons/b06618fb-859f-45b8-b327-ef31d32d5156/instagram-icon-f4e0d43d-afbf-4442-99a0-80ebaef1da4d.png?timestamp=1728726374528',
          width: 20,
          height: 20,
        },
        {
          key: 'linkedin',
          url: 'https://static.dev.sendsig.com/icons/b06618fb-859f-45b8-b327-ef31d32d5156/linkedin-icon-0d1fb796-de1d-498e-89b7-5a82b8df6b3b.png?timestamp=1728726374511',
          width: 20,
          height: 20,
        },
      ],
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 105,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 13,

            lineHeight: 1.09,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 445,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
        signoff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            width: 1,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },

    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshotUrl: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#F8CA0B',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 13',
      image: null,
      key: 'TEMPLATE_13',
      description: null,
      isActive: true,
      config: null,
      templateId: 13,
    },
    name: 'My Signature 13',
  },
];

export default Template13;
