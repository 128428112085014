import { getTemplateImages } from 'common/constants';

const Template2 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_2'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Alex Martinez',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'Creating Director',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: 'Management',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: 'Creative X',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '984 Penn Rd. NY 102',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: getTemplateImages('companylogo_TEMPLATE_2'),
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value:
          'The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof”',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'm@creativewonder.com',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '(345) 087 - 1239',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: 'https://creativewonders.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: '',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: 'https://facebook.com/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: 'https://www.instagram.com/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: 'https://www.linkedin.com/company/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      // {
      //   link: 'https://twitter.com/#',
      //   name: 'twitter',
      //   type: 'company',
      //   label: 'TWITTER',
      //   value: 'https://twitter.com/#',
      //   isVariable: false,
      //   utagEnabled: true,
      //   variableValue: null,
      // },
      {
        name: 'socialBanners',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],
    design: {
      primaryBrandColor: '#FF7A8A',
      font: 'Arial, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: [
        {
          key: 'phone',
          url: 'https://static.dev.sendsig.com/icons/b60698f9-9a03-47b1-96d8-827eafcea1b9/phone-icon-ca688dde-3952-4013-91f8-4bf8a2827f9f.png?timestamp=1728373485102',
          width: 16,
          height: 16,
        },
        {
          key: 'website',
          url: 'https://static.dev.sendsig.com/icons/b60698f9-9a03-47b1-96d8-827eafcea1b9/website-icon-5bf90dfd-0393-4661-b848-347c61143440.png?timestamp=1728373485198',
          width: 16,
          height: 16,
        },
        {
          key: 'address',
          url: 'https://static.dev.sendsig.com/icons/b60698f9-9a03-47b1-96d8-827eafcea1b9/address-icon-ce0c427b-7a8c-4c20-b878-f4d52af13fdf.png?timestamp=1728373485199',
          width: 16,
          height: 16,
        },
        {
          key: 'facebook',
          url: 'https://static.dev.sendsig.com/icons/b60698f9-9a03-47b1-96d8-827eafcea1b9/facebook-icon-71dbd0aa-bd52-4a0e-9b29-d25c00334638.png?timestamp=1728373485128',
          width: 29,
          height: 29,
        },
        {
          key: 'instagram',
          url: 'https://static.dev.sendsig.com/icons/b60698f9-9a03-47b1-96d8-827eafcea1b9/instagram-icon-35845b9a-8878-405e-838d-06f5cf333ac2.png?timestamp=1728373485199',
          width: 29,
          height: 29,
        },
        {
          key: 'linkedin',
          url: 'https://static.dev.sendsig.com/icons/b60698f9-9a03-47b1-96d8-827eafcea1b9/linkedin-icon-d1801658-c3e5-41bb-af06-153707938a84.png?timestamp=1728373485164',
          width: 29,
          height: 29,
        },
      ],
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 105,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 15,
            lineHeight: 1.09,
          },
        },
        headerFont: {
          style: {
            fontSize: 13,
          },
        },
        icon: {
          type: 'rounded',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 22,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 442,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
        signoff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            width: 1,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'd8cef969-cd60-402c-999b-9f172682abef',
    headshotId: null,
    headshotUrl: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FF7A8A',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'd8cef969-cd60-402c-999b-9f172682abef',
      name: 'template 2',
      image: null,
      key: 'TEMPLATE_2',
      description: null,
      isActive: true,
      config: null,
      templateId: 2,
    },
    name: 'My Signature 2',
  },
];

export default Template2;
